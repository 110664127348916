import Chart from 'com_diet/gchart';

const onLoad = function() {
  const wrapper = document.getElement('.mod-diet-demo');
  if(wrapper) {
    const chartBox = wrapper.getElement('.demo-chart');
    const chartDataBox = chartBox.getElement('.chartData');
    const chartData = JSON.decode(chartDataBox.get('text'));
    const canvas =  chartBox.getElement('canvas');
    new Chart(canvas, chartData.data, chartData.options);
  }
};

document.addEvent('domready', onLoad);