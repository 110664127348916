export default new Class({
	initialize: function(canvas, data, options){
		this.canvas = canvas;
		this.data = data;
		this.options = options;
		this.options.bottom = this.canvas.height - this.options.padding * 4 + 0.5;
		this.findMaxValues();
		this.timeline();
		this.drawLegend();
		
		for(var i = 0; i < data.length; i++){
			if('points' in data[i]){
				this.drawBars(data[i]);
			}
		}
		
	},

	findMaxValues: function(){
		let maxValues = ('maxValues' in this.options) ? this.options.maxValues : {};
		for(let i = 0; i < this.data.length; i++){
			for(const prop in this.data[i].points){
				if(prop in maxValues){
					maxValues[prop] = Math.max(maxValues[prop], this.data[i].points[prop]);
				}else{
					maxValues[prop] = this.data[i].points[prop];
				}
			}
		}
		this.options.maxValues = maxValues;
	},
	
	timeline: function(){
		const ctx = this.canvas.getContext('2d');
		ctx.fillStyle = 'green';
		ctx.beginPath();
		ctx.lineWidth = 1;
		let dH = this.options.bottom;
		let tH = dH + 17;
		let maxL = this.canvas.width - this.options.padding * 2;
		ctx.moveTo(this.options.padding, dH);
		ctx.lineTo(maxL, dH);
		ctx.stroke();
		const stepSize = (maxL - this.options.padding * 2) / (this.options.maxHour - this.options.minHour);
		this.options.stepSize = stepSize;
		ctx.textAlign = 'center';
		for(let i = this.options.minHour; i <= this.options.maxHour; i++){
			const base = 1.5 * this.options.padding + stepSize * (i - this.options.minHour);
			const time = moment(i + ':00', 'H:mm')
			ctx.fillText(time.format(this.options.hmFmt), base, tH);
		}
	},

	drawBars: function(data){
		const time = data.time, points = data.points;
		let barCount = 0;
		for(var point in points){
			barCount++;
		}
		const timeObj = moment(time, this.options.hmFmt);
		let dX = barCount - 1;
		const base = this.options.stepSize * (1 * timeObj.format('HH') - this.options.minHour
						+ timeObj.format('mm') / 60)
						+ this.options.padding 
						+ 0.5 * this.options.barWidth * this.options.barSpacing * dX;
		let top = this.options.padding;
		const ctx = this.canvas.getContext('2d');
		ctx.textAlign = 'center';
		ctx.font = 'bold 10px sans-serif';

		for(let point in points){
			const X = base - (this.options.barWidth * 1.2 * dX--);
			const maxH = this.options.bottom;
			const stepSize =  (maxH - (this.options.padding * 2)) / this.options.maxValues[point];
			const H = points[point] >= 1 ? (points[point] * stepSize) - 40 : 0;
			top = Math.min(top, maxH - H);
			ctx.fillStyle = this.options.colors[point];
			ctx.fillRect(X, maxH - H, this.options.barWidth, H);
			let text = (1 * points[point]).toFixed(1);
			ctx.fillText(text, X + this.options.barWidth * 0.5, maxH - H - 7);
			ctx.save();
			let timeY = maxH - H;
			if(H > 35){
				timeY += 35;
				ctx.fillStyle = '#fff';
			}else{
				timeY -= 25;
			}
			ctx.translate(X + this.options.barWidth * 0.75, timeY);
			ctx.rotate(3 * Math.PI / 2);
			ctx.textAlign = 'left';
			ctx.font = 'normal 10px sans-serif';
			text = timeObj.format(this.options.hmFmt);
			if('xinfo' in data){
				if(point in data.xinfo){
					text = data.xinfo[point];
				}
			}
			ctx.fillText(text, 0, 0);
			ctx.restore();
		}
	},
	
	drawLegend: function(){
		let X = 100;
		const ctx = this.canvas.getContext('2d');
		for(let index in this.options.colors){
//			ctx.fillRect(X, 10, X + 20, 20);
			ctx.font = 'bold 14px sans-serif';
			ctx.textAlign = 'left';
			ctx.fillStyle = this.options.colors[index];
			ctx.fillText(this.options.labels[index], X, 10);
			X += 40 + ctx.measureText(this.options.labels[index]).width;
		}
	}
});